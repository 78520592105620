module.exports = {
    nonMemberCategory: {
        1: 'IBBI Registered Valuer', 
        //2: 'Sponsor Deligate', 
        3: 'Foreign Deligates', 
        4: 'Others'
    },
    Registertype: {
        1: 'Registeration', 
        2: 'Residential Registeration'
    },
    Usertype: {1:'Accompanying Delegate',2:"Delegate",3:"Guest",4:"Media",5:"Organiser",6:"Speaker",7:"Sponsor",8:"VIP",9:"Volunteer"},
    Activities: {1:'Day 1 - Entry',2:"Day 1 - Kit Handover",3:"Day 1 - Lunch",4:"Day 1 - Dinner",5:"Day 2 - Check-in",6:"Day 2 - Lunch",7:"Day 2 - Certificate"},
    Activities_1: {1:'Day 1 - Entry',2:"Day 1 - Kit Handover",3:"Day 1 - Lunch"},
    Activities_2: {4:"Day 1 - Dinner",5:"Day 2 - Check-in",6:"Day 2 - Lunch",7:"Day 2 - Certificate"},

}