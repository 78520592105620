import React from "react";
import { useTimer } from "react-timer-hook";
import { Link } from "react-router-dom";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div style={{ textAlign: "center" }}>
      {/* <h1>react-timer-hook </h1> */}
      {/* <p>Timer Demo</p> */}
      <div
        className="bg-auto-repeat background-image"
        data-pos-for=".countdown-section"
        data-sec-pos="bottom-half"
      >
        <div className="container z-index-common">
          <ul
            className="event-counter style5 counter-list"
            data-offer-date="03/03/2023"
          >
            <li>
              <div className="day count-number">{days}</div>
              <span className="count-name">Days</span>
            </li>
            <li>
              <div className="hour count-number">{hours}</div>
              <span className="count-name">Hours</span>
            </li>
            <li>
              <div className="minute count-number">{minutes}</div>
              <span className="count-name">Minutes</span>
            </li>
            <li>
              <div className="seconds count-number">{seconds}</div>
              <span className="count-name">Seconds</span>
            </li>
          </ul>
        </div>
      </div>
      {/* <div style={{ fontSize: '3rem' }}>
                <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div> */}
      {/* <p>{isRunning ? 'Running' : 'Not running'}</p>
        <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={resume}>Resume</button>
        <button onClick={() => {
          // Restarts to 5 minutes timer
          const time = new Date();
          time.setSeconds(time.getSeconds() + 300);
          restart(time)
        }}>Restart</button> */}
    </div>
  );
}

const AboutHome = () => {
  let secondBetweenTwoDate = Math.abs(
    (new Date("2022-12-16").getTime() - new Date().getTime()) / 1000
  );
  // console.log('secondBetweenTwoDate', secondBetweenTwoDate, 'date', new Date("2022-12-16"), 'days', secondBetweenTwoDate/86400)
  const time = new Date();
  time.setSeconds(time.getSeconds() + secondBetweenTwoDate);
  return (
    <div>
      <section className="space">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-lg-0 mb-60">
              <div className="about-img4">
                <img src="./img/National-Seminar-new.jpg" alt="about image" />
                {/* <div className="speaker-count">
                                        <div className="counter-number">15</div>
                                        <span className="text">Iconic Speakers</span>
                                    </div> */}
              </div>
            </div>
            <div className="col-lg-7 ps-xl-5">
              <span className="sub-title2">
                More About Us
                <img
                  className="title-shape"
                  src="./img/title_shape.png"
                  alt="title shape"
                />
              </span>
              <h2 className="sec-title-ndm mb-30">About The Event</h2>
              <p className="mb-35">
                <strong>Indian Valuers Congress (IVC)</strong>, is an annual
                event organised by Institution of Valuers (IOV). It is a
                benchmarking platform in the careers of Valuers from all over
                India and overseas. In its endeavour for marking India&#39;s
                presence in valuation field globally, IVC provides a crucial
                platform for disseminating know-how on Valuation and showcasing
                the profound impact on the current practices of the valuation
                profession through interaction amongst the valuers and
                stakeholders beyond boundaries.
              </p>
              <div className="info-media-wrap border-0 mb-1">
                <div className="info-media col-md-12 col-12">
                  <div className="info-media_icon">
                    <i className="fas fa-calendar-alt"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="info-media_title">When</h3>
                    <p className="info-media_text">
                      5th, 6th and 7th December 2024
                    </p>
                  </div>
                </div>

                <div className="info-media col-md-12 col-12">
                  {/* <div className="info-media_icon">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div> */}
                  <div className="media-body text-center">
                    {/* <h3 className="info-media_title">16th December 2022</h3> */}
                    <Link to="/register" className="btn-grad me-4">
                      Register Now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="info-media-wrap border-0">
                <div className="info-media col-md-12 col-12">
                  <div className="info-media_icon">
                    <a
                      href="https://maps.app.goo.gl/ReUyhuBXqynj4o7H6"
                      target="_blank"
                    >
                      {" "}
                      <i className="fas fa-map-marker-alt custom-map-marker"></i>{" "}
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="info-media_title">Venue</h3>
                    <p className="info-media_text">
                      Gokulam Park Hotel &amp; Convention Centre, Banerji Road,
                      Kaloor, Kochi, Kerala - 682017.
                    </p>
                  </div>
                </div>
              </div>
              <div className="btn-group">
                {/* <MyTimer expiryTimestamp={time} /> */}
                {/* <a href="#" className="popup-video video-btn"><i className="fas fa-play"></i>Watch Video</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHome;
