import React, { useEffect, useState,useRef } from 'react';
import Slider from "react-slick";
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { checkData } from '../../ObjectExist';

const TopSlider = () => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };
    const videoStyles = {
        width: '100%', // Make the video take the full width of the parent div
        objectFit: 'cover', // Cover the div completely
      };


    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`banner/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
       // onApiInteg();

     
      
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`pages/view`, data).then((res) => {
            console.log('res', res)
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
 


    }, []);

    return (
        <div>

            <div class="slick-track">
                <video
                width="1200" // Ensure this matches the desired width
                height="500" // Ensure this matches the desired height
                src="https://iovemailassets.s3.ap-south-1.amazonaws.com/IOV+Kochi+02+4K.mp4"
                title="Video Player"
                style={videoStyles}
                muted
                autoPlay
                loop
                playsInline
                referrerPolicy="strict-origin-when-cross-origin"
                >
                     Your browser does not support the video tag.
                     </video>
                  </div>
            

            {/* <Slider {...settings}>
                {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (
                    <div>
                        <img src={`${apiData.path}/${item.image}`} alt='"Kochi","IOV", "IVC", "indian valuers congress", "Valuers", "institution of valuers", "valuation"' />
                    </div>
                )) : ''}
            </Slider> */}
        </div>
    )
}

export default TopSlider