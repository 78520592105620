import React, { useState, useEffect } from "react";
import http from "../../CommonAxios";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const CmsPages = () => {
  let cmsId = new URLSearchParams(window.location.search).get("s");
  const [apiData, setApiData] = useState({});
  const [loader, setLoader] = useState(false);
  const [bgImage, setBgImage] = useState(false);
  const { slug } = useParams();
  const onApiInteg = () => {
    let data = { admin_id: 1, slug: slug };
    setLoader(true);
    http
      .post(`pages/view`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          setApiData(res.data.data);
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (slug == "about-us") {
      setBgImage("About-Us-Banner.jpg");
    } else if (slug == "partners") {
      setBgImage("Partners-Banner.jpg");
    } else if (slug == "about-iov") {
      setBgImage("About-IOV-Banner.jpg");
    } else if (slug == "about-ivc") {
      setBgImage("About-IVC-Banner.jpg");
    } else if (slug == "theme-of-55th-ivc") {
      setBgImage("Theme-of-55th-IVC-Banner.jpg");
    } else if (slug == "thematic-issues") {
      setBgImage("Thematic-issues.jpg");
    } else if (slug == "conference-advisory-group-of-iov") {
      setBgImage("Conference-Advisory-Group-of-IOV-banner.jpg");
    } else if (slug == "about-kochi") {
      setBgImage("About-Kochi.jpg");
    } else if (slug == "privacy-policy") {
      setBgImage("Privacy-Policy-Banner.jpg");
    } else if (slug == "terms-and-condition") {
      setBgImage("Terms-and-Condition-Banner.jpg");
    } else if (slug == "tandc-registration") {
      setBgImage("Terms-and-Condition-for-Registration.jpg");
    } else if (slug == "travel-desk" ){
      setBgImage("Travel-Desk-Banner.jpg");
    } else {
      setBgImage("breadcrumb-bg.jpg");
    }

    onApiInteg();
    window.scrollTo(0, 0);
  }, [slug]);
  //console.log('apiData', apiData)
  return (
    <div className="element">
      <div class="breadcumb-wrapper background-image">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/img/${bgImage}`}
          alt="banner"
          className="w-100 img-fluid"
        />
      </div>
      {checkData(apiData) ? (
        <section class="space">
          <div class="container">
            <div class="row">
              {/* <div class="col-lg-6 pe-xl-5">
                            <div class="about-img">
                                <img src={`${process.env.REACT_APP_BASE_URL}/img/about-us.jpg`} alt="CMS image" class="w-100" />
                            </div>
                        </div> */}
              <div class="col-lg-12 align-self-center">
                <div class="title-area text-sm-start text-center mb-30">
                  {/* <span class="sub-title ">Become Successful Today!</span> */}
                  {/* <h1>{checkUndeNullBlank(apiData[0].title) ? apiData[0].title : ''}</h1> */}
                </div>
                <p class="mb-40 text-sm-start text-center">
                  {checkUndeNullBlank(apiData[0].long_desc)
                    ? parse(apiData[0].long_desc)
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};

export default CmsPages;
